<template>
  <div class="container">
    <div class="filter-box">
      <Input class="search-input" v-model="table.search" placeholder="请输入搜索内容" style="width: 500px" size="large" maxlength="30" :clearable="true" @on-change="onSearchChange" search @keyup.enter.native="submitSearch"></Input>
      <Button class="search-btn" type="primary" icon="ios-search" size="large" @click="submitSearch">搜索</Button>
    </div>
    <Button type="error" ghost class="post-btn" @click="onPost">上传资料</Button>
    <div class="prop-row">
      <div class="label">类别：</div>
      <div class="values">
        <a v-for="(item, index) in dataCategoryData" :key="item.id" :class="['item', {'item-active': selCategory == index}]" @click="onCategoryClick(index)">{{item.name}}</a>
      </div>
    </div>
    <div v-if="subCategory" class="prop-row">
      <div class="label">详别：</div>
      <div class="values">
        <a v-for="(item, index) in subCategory" :key="item.id" :class="['item', {'item-active': selSubCate == index}]" @click="onSubCateClick(index)">{{item.name}}</a>
      </div>
    </div>
    <div class="data-record__panel">
      <template v-if="table.rows.length > 0">
        <div v-for="item in table.rows" :key="item.id" class="data-record-box" @click="onDataRecordItemClick(item.id)">
          <viewer>
            <img class="img" :src="item.image" @error="item.image = nopic"/>
          </viewer>
          <div class="info-panel">
            <div class="top">
              <div class="title">{{item.title}}</div>
              <div class="create"><span>{{item.create_date}}</span></div>
            </div>
            <!-- <div class="detail">{{item.detail}}</div> -->
            <div class="detail"></div>
            <div class="bottom">
              <!-- <div v-if="item.expire_date !== null" class="expire-date">截至日期：<span>{{dateFormat("YYYY-mm-dd", new Date(item.expire_date))}}</span></div> -->
              <div v-if="item.expire_date !== null" class="expire-date">{{ item.attachments.length }}个文件</div>
              <div>
                <!-- <span>联系人：</span><span class="contact">{{item.contact}}</span> -->
                <!-- <span style="margin-left: 20px;">联系电话：</span><span class="contact">{{item.phone}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="nodata-tip">暂无数据</div>
      <!-- 分页 -->
      <Page v-if="table.totalCount > table.pageSize" :total="table.totalCount" show-total :page-size="table.pageSize" :current="table.currentPage" @on-change="onPageChange" style="margin-top:5px;" />
      <Spin size="large" fix v-if="table.loading"></Spin>
    </div>
    <AddDataRecord v-model="showAddDataRecord" :dataRecord-info="dataRecordInfo" @on-success="onAddDataRecordSuccess"/>
  </div>
</template>

<script>
import AddDataRecord from '@/components/AddDataRecord.vue'
import { mapMutations, mapGetters } from 'vuex'
import { getDataIndex, getDataRecordList } from '@/api/data.js'
import { dateFormat } from '@/libs/util.js'

export default {
  name: 'Data',
  components: {
    AddDataRecord
  },
  data() {
    return {
      nopic: require('@/assets/images/nopic.png'),

      showAddDataRecord: false,
      dataRecordInfo: null,

      dataCategoryData: null,

      selCategory: null,
      selSubCate: null,

      // 列表数据
      table: {
        loading: false,
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
        rows: [],
        search: null
      }
    }
  },
  computed: {
    ...mapGetters(['hasLogin', 'cityId']),
    subCategory() {
      if (this.selCategory > 0) {
        const tmp = this.dataCategoryData[this.selCategory].sub_category
        if (tmp && tmp.length > 0) {
          return [{id: -1, name: '全部'}].concat(tmp)
        }
      }
      return null
    }
  },
  watch: {
    showAddDataRecord(newVal) {
      if (!newVal) {
        this.dataRecordInfo = null
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapMutations(['showLoginDialog']),
    dateFormat,
    async initData() {
      try {
        const res = await getDataIndex()
        this.dataCategoryData = [{id: 0, name: '全部'}].concat(res.content.category)/* .concat({id: -1, name: '其他'}) */
        this.selCategory = 0

        this.getDataRecordListData()
      } catch (error) {
        this.table.loading = false
        this.$Message.error(error.message)
      }
    },
    async getDataRecordListData() {
      try {
        this.table.loading = true
        const start = (this.table.currentPage - 1) * this.table.pageSize
        const params = {start, count: this.table.pageSize}
        if (this.subCategory) {
          if (this.selSubCate > 0) {
            params.data_category_id = this.subCategory[this.selSubCate].id
          } else {
            params.data_category_id = this.dataCategoryData[this.selCategory].id
          }
        } else {
          params.data_category_id = this.dataCategoryData[this.selCategory].id
        }
        params.search = this.table.search
        params.city_id = this.cityId
        const res = await getDataRecordList(params)
        this.table.totalCount = parseInt(res.content.total_count)
        this.table.rows = res.content.rows
        this.table.loading = false
      } catch (error) {
        this.table.loading = false
        this.$Message.error(error.message)
      }
    },
    async onPageChange(val) {
      this.table.currentPage = val
      await this.getDataRecordListData()
    },
    // 搜索内容改变
    async onSearchChange(e) {
      if (this.table.search == '') { // 清空内容时刷新列表
        await this.submitSearch()
      }
    },
    async submitSearch() {
      this.table.currentPage = 1
      await this.getDataRecordListData()
    },
    onCategoryClick(index) {
      this.selCategory = index
      this.selSubCate = 0
      this.getDataRecordListData()
    },
    onSubCateClick(index) {
      this.selSubCate = index
      this.getDataRecordListData()
    },
    onDataRecordItemClick(id) {
      const routeData = this.$router.resolve({ name: 'DataRecordDetail', query: {id} })
      window.open(routeData.href, '_blank')
    },
    onPost() {
      if (this.hasLogin) {
        const dataRecordInfo = {}
        if (this.subCategory) {
          if (this.selSubCate > 0) {
            dataRecordInfo.c_id = this.subCategory[this.selSubCate].id
          } else {
            dataRecordInfo.c_id = this.dataCategoryData[this.selCategory].id
          }
        } else {
          dataRecordInfo.c_id = this.dataCategoryData[this.selCategory].id
        }

        dataRecordInfo.isAdd = 1
        this.dataRecordInfo = dataRecordInfo
        this.showAddDataRecord = true
      } else {
        this.showLoginDialog()
      }
    },
    onAddDataRecordSuccess() {
      // this.getDataRecordListData()
      this.$router.push({ path: '/user', query: { menuActive: '0' } })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  // background: #ccc;
  padding: 20px 0 40px;
}

.filter-box {
  text-align: center;
  padding: 20px 0;

  .search-input {

  }
  .search-btn {
    margin-left: 10px;
  }
}

.post-btn {
  float: right;
  // color: #ff552e;
}

.prop-row {
  margin-bottom: 5px;
  display: flex;
  overflow: hidden;
  line-height: 34px;
  font-size: 15px;
  .label {
    color: #333;
    width: 80px;
    text-align: right;
    margin-right: 10px;
    flex: 0 0 auto;
  }
  .values {
    .item {
      display: inline-block;
      white-space: nowrap;
      color: #333;
      padding: 0 2px;
      margin: 0 6px;
    }
    .item:hover {
      color: #ff552e;
    }
    .item-active {
      color: #ff552e;
      font-weight: 700;
    }
  }
}

.data-record__panel {
  margin-top: 30px;
  position: relative;
}

.data-record-box {
  display: flex;
  height: 140px;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  border-top: solid 1px #dbdde1;
  padding: 10px 20px;
  cursor: pointer;

  .img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
  }
  .info-panel {
    flex: 1;
    height: 100px;
    overflow: hidden;

    .top {
      display: flex;
      .title {
        flex: 1;
        color: #000;
        font-size: 18px;
        height: 25px;
        line-height: 25px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .create {
        color: #999;
        padding-left: 5px;
      }
    }
    .detail {
      margin-top: 5px;
      color: #888;
      font-size: 15px;
      line-height: 20px;
      height: 40px;

      overflow: hidden;
      word-break: break-all;  /* break-all(允许在单词内换行。) */
      text-overflow: ellipsis;  /* 超出部分省略号 */
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 2; /** 显示的行数 **/
    }
    .bottom {
      display: flex;
      margin-top: 10px;
      height: 20px;
      justify-content: space-between;
      color: #666;
      .expire-date {
        color: #999;
      }
      .contact {
        color: #2e86b9
      }
    }
  }
}

</style>
